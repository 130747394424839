/**
 * Environment for development version. This environment auto-deploys each change pushed to develop branch.
 * Releases are made for each pushed commit.
 *
 * ??? This environment communicates with dev/test version of other integrations. (Eg: a HUPX trade is sent to test HUPX system.)
 */
export const environment = {
  enableAngularProdMode: true,
  reportErrors: false,
  environment: 'test',
  frontendVersion: '1.2.3 (from v1.2.3 at 392d637)',
  /** The development and test stage shares the same app registration. */
  azureADTenantID: '94fa3ab9-f544-42c1-983e-d6d2a751ef5e',
  azureADClientID: '0fe05e8f-0250-4dee-8f17-ab038cdd06c6',
  azureADApiAppURI: 'api://alteo-sinergy-platform-dev-api',
  apiBasePath: 'https://api.platform.test.artemis-energy.com',
  pagination: {
    defaultPageLimit: 50,
  },
};
